<template>
  <div class="category">
    <div class="head">
      <div class="title">{{ category.label }}</div>
      <div class="action">
        <button
          class="button button__add"
          @click="toggleContentForm"
        >Add content</button>
        <button
          class="button button__delete"
          @click="handleDeleteCategory"
        >Delete Category</button>
      </div>
    </div>
    <div class="contents">
      <div class="gtable">
        <div class="gtable__head">
          <div class="name">
            <div class="field">
              Title
            </div>
            <div class="field">
              Date
            </div>
            <div class="field">
              Pdf Id
            </div>
            <div class="field">
              Image Preview
            </div>
            <div class="field">
              Updated By
            </div>
            <div class="field">
              Actions
            </div>
          </div>
        </div>
      </div>
      <ContentItem
        v-for="content in contents"
        :key="content.id"
        :content="content"
        @delete="handleDeleteContent"
        @update="handleUpdateContent"
      />
    </div>
    <ContentForm
      v-if="visibleContentForm"
      @close="toggleContentForm"
      :contentData="editContentData"
      :categoryId="categoryId"
      @success="successSubmitContent"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const ContentItem = () => import('@/components/pdf/ContentItem')
const ContentForm = () => import('@/components/pdf/ContentForm')
export default {
  name: 'CategoryContainer',
  components: {
    ContentItem,
    ContentForm
  },
  props: {
    category: {
      default: () => ({}),
      type: Object
    }
  },
  data () {
    return {
      contents: [],
      visibleContentForm: false,
      editContentData: null
    }
  },
  created () {
    this.initComponent()
  },
  computed: {
    ...mapGetters('user', ['token']),
    categoryId () {
      return this.category.id
    }
  },
  methods: {
    ...mapActions('pdf', ['fetchContents', 'deleteOldContents', 'updateOldContents']),
    initComponent () {
      this.fetch()
    },
    fetch () {
      this.fetchContents({
        categoryId: this.categoryId,
        success: this.successFetchContents
      })
    },
    successFetchContents (data) {
      this.contents = [...data]
    },
    removeCategory () {
      return null
    },
    addContent () {
      return null
    },
    handleDeleteContent ({ id }) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan dapat mengembalikan ini!',
        icon: 'warning',
        confirmButtonText: 'Yes, hapus!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteOldContents({
            data: {
              categoryId: this.categoryId,
              contentId: id,
              token: this.token
            },
            success: this.successDeleteContent
          })
        }
      })
    },
    successDeleteContent () {
      this.$swal({
        icon: 'success',
        title: 'Success Delete',
        showConfirmButton: false,
        timer: 1500
      })
      this.fetch()
    },
    handleUpdateContent (data) {
      this.editContentData = { ...data }
      this.toggleContentForm(true)
    },
    toggleContentForm (update = false) {
      if (!update) this.resetEditContentData()
      this.visibleContentForm = !this.visibleContentForm
    },
    resetEditContentData () {
      this.editContentData = null
    },
    successSubmitContent (isEdit) {
      this.toggleContentForm()
      this.$swal({
        icon: 'success',
        title: isEdit ? 'Success Edit' : 'Success Add New',
        showConfirmButton: false,
        timer: 1500
      })
      this.fetch()
    },
    handleDeleteCategory () {
      this.$emit('deleteCategory', this.categoryId)
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  border-bottom: 2px solid #000;
  margin: 16px 0;
  .head {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .action {
      .black {
        color: #000;
      }
    }
  }
}
</style>
